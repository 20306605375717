import * as React from "react";
import { Link } from "gatsby";

import {
  Box,
  Text,
  Button,
  useColorMode,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

// markup
const Contact = () => {
  const { colorMode } = useColorMode();
  return (
    <Box
      //  bg="gray.50"
      pt="40px"
      maxW={{ base: "xl", md: "4xl" }}
      mx="auto"
      px={{ base: "6", md: "40px" }}
    >
      <Header />
      <Box mt="100px">
        <Link to="/">
          <Text
            mt="40px"
            mb="20px"
            size="xl"
            fontWeight="extrabold"
            maxW="2xl"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
            color={colorMode === "light" ? "gray.500" : "whiteAlpha.900"}
            _hover={
              colorMode === "light"
                ? { color: "#333" }
                : { color: "whiteAlpha.700" }
            }
          >
            <ChevronLeftIcon />
            Retour
          </Text>
        </Link>
        <Text
          fontSize={{ base: "44px", md: "68px" }}
          fontWeight="extrabold"
          lineHeight="1.1"
          bgGradient="linear(to-l,  #199ECF , #006ECF,)"
          bgClip="text"
          // color="blue.500"
        >
          Contact
        </Text>
        <Box
          as="form"
          name="contact-form-V1"
          method="POST"
          data-netlify="true"
          onSubmit="submit"
          action="/confirmation/"
        >
          <input type="hidden" name="form-name" value="contact-form-V1" />
          <FormControl isRequired my="20px">
            <FormLabel>Nom</FormLabel>
            <Input type="text" name="name" />
          </FormControl>
          <FormControl isRequired my="20px">
            <FormLabel>Société</FormLabel>
            <Input type="text" name="company" />
          </FormControl>
          <FormControl isRequired my="20px">
            <FormLabel>Adresse email</FormLabel>
            <Input name="email" />
          </FormControl>
          <FormControl isRequired my="20px">
            <FormLabel isRequired>Message</FormLabel>
            <Textarea name="message" size="lg" isRequired />
          </FormControl>
          <Button type="submit" my="20px">
            Envoyer
          </Button>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Contact;
